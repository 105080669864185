.MuiDialog-container .MuiDialog-paperWidthSm {
   max-width: 100%;
   width: 90%;
}

.MuiBox-root .MuiFormControlLabel-root{
   margin-left: 0;
   margin-right: 0;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
   background-color: #004a30 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
   color: #004a30 !important;
}

#greenHead {
   width: 100%;
   background-image: linear-gradient(#004a30, #00291b);
}

button.MuiButtonBase-root.MuiIconButton-root.topButtons.closeIconButton {
   color: #fff;
}

#opacityGreen {
   padding: 1.5% 2% 1.5% 2%;
   background-color: #004a3013;
   display: flex;
   flex-wrap: wrap;
}

.MuiPaper-root .MuiDialogContent-root:first-child {
   padding: 0;
   padding-top: 0;
}

#projectTitle {
   background: #ffffff;
}

#companyName {
   background: #ffffff;
}

#companyWebsite {
   background: #d9d9d9;
}

#greenHead .MuiBox-root .MuiFormControl-root {
   display: inline-flex;
   margin-left: auto;
   margin-right: auto;
   padding-bottom: 15px;
   width: 80%;
}

.MuiFormControl-root .MuiInputLabel-outlined {
   padding-top: 50px;
   padding-bottom: 15px;
}

#greenHeadMainChild {
   width: 100%;
   padding-top: 3%;
}

#projectTitleBox {
   width: 100%;
}

#companyBox {
   width: 80%;
   margin-left: auto;
   margin-right: auto;
}

.MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
   max-width: 100%;
   width: 30%;
}

.MuiBox-root .opacityGreenTitle {
   font-family: Roboto;
   font-weight: 700;
   font-style: normal;
   font-size: 14px;
   line-height: 16px;
}

textarea#projectDescription {
   margin-bottom: 2%;
   margin-left: 5%;
   width: 90%;
   height: 180px;
}

#projectDescriptionTitle {
   margin-left: 5%;
   margin-top: 1.5%;
   margin-bottom: 0.5%;
}

textarea#companyDescription {
   width: 100%;
   height: 100px;
}

#companyDescriptionTitle {
   margin-top: 1.5%;
   margin-bottom: 0.5%;
}

#publishState {
   left: 3%;
   font-family: Roboto;
   font-weight: 500;
   font-style: normal;
   font-size: 23px;
   color: #979797;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.topButtons {
   text-transform: none;
   color: #fff;
   border: 1px solid #fff;
}

#projectTitleView {
   padding-top: 1%;
   font-family: Roboto;
   font-weight: 500;
   font-style: normal;
   color: #ffffff;
   font-size: 48px;
   line-height: 48px;
}

#companyNameView {
   padding-left: 60px;
   padding-top: 1%;
   font-family: Roboto;
   font-weight: 400;
   font-style: normal;
   color: #ffffff;
   font-size: 24px;
   line-height: 24px;
}

#greenHeadView {
   padding-bottom: 1.5%;
   width: 100%;
   background-image: linear-gradient(#004a30, #00291b);
}

#opacityGreenView {
   padding: 1.5% 5% 1.5% 5%;
   background-color: #004a3013;
   display: flex;
   flex-wrap: wrap;
}

p.MuiTypography-root.opacityGreenTitleView.MuiTypography-body1 {
   font-family: Roboto;
   font-weight: 800;
   font-style: normal;
   font-size: 14px;
   line-height: 16.5px;
   color: #50555c;
}

p.MuiTypography-root.opacityGreenPropertyView.MuiTypography-body1 {
   font-family: Roboto;
   font-weight: 500;
   font-style: normal;
   font-size: 14px;
   line-height: 16.5px;
   color: #50555c;
}

.MuiTypography-root.opacityGreenPropertyView.MuiTypography-body1 {
   font-family: Roboto;
   font-weight: 500;
   font-style: normal;
   font-size: 14px;
   line-height: 16.5px;
   color: #50555c;
}

.opacityGreenBox {
   max-width: 150px;
   margin-right: 3%;
   margin-bottom: 1%;
}

#projectTitleWhiteBox {
   font-family: Roboto;
   font-weight: 700;
   font-style: normal;
   font-size: 24px;
   line-height: 30px;
   color: #50555c;
   margin-bottom: 1%;
}

#projectDescriptionWhiteBox {
   font-family: Roboto;
   font-weight: 500;
   font-style: normal;
   font-size: 14px;
   line-height: 20px;
   color: #50555c;
}

#projectSponsorWhiteBox {
   font-family: Roboto;
   font-weight: 600;
   font-style: normal;
   font-size: 24px;
   line-height: 30px;
   color: #50555c;
   margin-bottom: 7%;
}

#projectSponsorNameWhiteBox {
   font-family: Roboto;
   font-weight: 700;
   font-style: normal;
   font-size: 16px;
   line-height: 19px;
   color: #004a30;
}

#projectSponsorRoleWhiteBox {
   font-family: Roboto;
   font-weight: 400;
   font-style: normal;
   font-size: 16px;
   line-height: 19px;
   color: #50555c;
}

#commentsBox {
   width: 90%;
   padding-top: 3%;
   padding-left: 60px;
   display: flex;
}

#commentsTitle {
   font-family: Roboto;
   font-weight: 500;
   font-style: normal;
   font-size: 24px;
   line-height: 30px;
   color: #004a30;
   margin-left: 1%;
}
#arrowBack {
   position: absolute;
   left: 0;
   top: 50%;
}
#arrowForward {
   position: absolute;
   right: 0;
   top: 50%;
}

#submitButton {
   color: #fff;
   background-color: #004a30;
}

#topBoxGreen {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected {
    background-color: #004A30;
    color: white;
}

@media only screen and (max-width: 650px) {
   #submitButton {
      width: 70% !important;
   }

   #projectTitleView {
      padding-left: 40px;
   }

   #topBoxGreen {
      padding-left: 40px;
      padding-top: 5px;
      display: grid;
   }

   #opacityGreenView {
      padding: 15px;
   }

   .opacityGreenBox {
      padding-right: 15px;
      padding-bottom: 10px;
   }

   #projectSponsorCard {
      margin-left: 65px;
      width: 200px;
      height: 200px;
      padding: 1.5%;
      margin-top: 1%;
   }

   #contactMeButton {
      background: #004a30;
      text-transform: none;
      color: #ffffff;
      margin-top: 10%;
   }

   #projectDescriptionBox {
      padding: 2% 40px 0 40px;
      text-align: center;
   }

   #projectDescriptionSponsor {
      display: flex;
      flex-direction: column;
   }

   #commentsBox {
      padding-left: 40px;
      flex-direction: column;
   }

   .opacityGreenField {
      margin-right: 10px !important;
      margin-bottom: 10px !important;
      width: 130px;
   }

   #companyDescriptionBox {
      width: 65%;
      padding-top: 7%;
      padding-bottom: 5%;
      padding-left: 1%;
   }

   #topButtonsBox {
      padding-top: 2%;
      padding-left: 2%;
   }

   .cardsList {
      padding-left: 5%;
   }

   .projectDescriptionCard {
      text-align: center;
   }

   button.MuiButtonBase-root.MuiIconButton-root.topButtons.closeIconButton {
      position: absolute;
      top: 0;
      right: 0;
   }
}

@media only screen and (min-width: 650px) {
   #submitButton {
      width: 180px !important;
   }

   #topBoxGreen {
      padding-left: 60px;
      padding-top: 15px;
   }

   #projectTitleView {
      padding-left: 60px;
   }

   #submitButtonBox {
      padding-left: 15%;
      padding-bottom: 0.5%;
      align-items: flex-end;
      display: flex;
   }

   .MuiPaper-root.MuiCard-root.MuiPaper-outlined.MuiPaper-rounded {
      overflow: unset;
      padding-left: 1%;
   }

   #projectSponsorCard {
      overflow: hidden;
      position: absolute;
      right: 2%;
      padding: 1.5%;
      margin-top: 1%;
      margin-right: 5%;
   }

   #contactMeButton {
      background: #004a30;
      text-transform: none;
      color: #ffffff;
      margin-top: 15%;
   }

   #projectDescriptionSponsor {
      display: flex;
      position: relative;
      justify-content: space-between;
      padding-left: 60px;
      padding-right: 6%;
      padding-top: 1%;
   }

   .opacityGreenField {
      margin-right: 10px !important;
      width: 150px;
   }

   #companyDescriptionBox {
      width: 90%;
      padding-left: 3%;
      position: relative;
      display: flex;
   }

   #topButtonsBox {
      padding-top: 1%;
      float: right;
   }

   #projectTitleBox {
      padding-top: 2%;
   }

   #projectDescriptionBox {
      width: fit-content;
      padding-right: 2%;
      text-align: justify;
   }
}
