@media only screen and (min-width: 600px) {
   .drawerContent {
      display: grid;
      padding: 20px;
   }
   .drawerFlex {
      display: inline-flex;
      flex-wrap: wrap;
      padding: 19px;
   }
   .forgotPassword {
      text-align: center;
      padding-top: 70px;
   }
   .signUp {
      display: block;
      text-align: center;
      padding-top: 70px;
   }
}

@media only screen and (max-width: 600px) {
   .drawerContent {
      padding-right: 20px;
      padding-left: 20px;
   }
   img {
      display: none;
   }
   .drawerFlex {
      padding-bottom: 5%;
   }
}
